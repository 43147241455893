import React, { useEffect, useState } from "react";
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Hidden } from "@material-ui/core";
import * as styles from "../landingPageComponents/adviserList.module.css";
import Container from "../../components/container";

import FundListCardForFundInfo from "./fundListCardForFundInfo";

const useStyles = makeStyles((theme) => ({
  root: {
    '& .Mui-selected': {
      background: '#3C465F;',
      color: 'white',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '20px',
      height: '3.5em',
      width: '3.5em',
      borderRadius: '1.75em',
      [theme.breakpoints.down("sm")]: {
        height: '3em',
        width: '3em',
        borderRadius: '1.5em',
        fontSize: '9px'
      },
    },
    '& .MuiPaginationItem-ellipsis': {
      paddingTop: '.8em'
    },
    '& .MuiPaginationItem-icon': {
      fontSize: '1.5rem'
    },
    '& .MuiPaginationItem-root': {
      [theme.breakpoints.down('sm')]: {
        minWidth: 'initial !important'
      }
    },
    '& ul > li > button:not(.Mui-selected),.MuiPaginationItem-ellipsis': {
      backgroundColor: 'white',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '20px',
      color: '#2F2F4E',
      height: '3.5em',
      width: '3.5em',
      borderRadius: '1.75em',
      [theme.breakpoints.down("sm")]: {
        height: '3em',
        width: '3em',
        borderRadius: '1.5em',
        fontSize: '9px'
      },
    }
  },


}));

function FundByID(props) {
  const [funds, setFunds] = useState([]);
  const [totalRows, setTotalRows] = useState(0);

  const apiURL = process.env.GATSBY_FUNDSAPI + props.FundID;

  useEffect(() => {
    (async () => {
      await searchPageLoad();
    })();
  }, []);

  var searchPageLoad = async function () {
    var funds = await getFunds();

    if (funds.length > 0) {
      setFunds(funds);
    }
  }

  var getFunds = async function (model) {
    return fetch(apiURL, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(model)
    })
      .then((response) => response.json())
      .then((responseJson) => { return responseJson });
  }

  return (

        <div>
          {funds && funds.map((f) => {
            return <FundListCardForFundInfo fund={f} from="ID" />
          })}
        </div>

  )
}

export default FundByID;
