import { graphql } from "gatsby";
import FundInfo from "../components/fundInfo";
import React from "react";
import GraphQLErrorList from "../components/graphql-error-list";
import Layout from "../containers/layout";
import Container from "../components/container";
import SEO from "../components/seo";
import { getPageUrlOutOfSanityReference, toPlainText } from "../lib/helpers";

export const query = graphql`
  query FundInfoTemplateQuery($id: String!) {
    post: sanityFundInfo(id: { eq: $id }) {
      title
      fundProvider
      dashboardId
      fundCompany {
          dashboardId
      }
      _type
      _rawBody(resolveReferences: {maxDepth: 10})
      sponsoredFund
      subtitle
      slug {
        current
      }
      _rawSponsoredAbout(resolveReferences: {maxDepth: 10})
      _rawMainImage
      _rawExcerpt
       mainImage {
        ...SanityImage
        alt
      }
      widgets {
        widgetType {
          title
        }
        title
        adZone
        mobileAdZone
        _rawBody
        _rawMainImage
        id
        mainImage {
          ...SanityImage
          alt
        }
        codeSnippet {
          children {
            text
          }
        }
        linkReference {
        ... fragLinkReference
        }
      }
      linkMenu {
          menuLinksTop {
            text
            reference {
              AnchorName
            }
            menuChildLinks {
              text
              reference {
                AnchorName
              }
            }
          }
          menuTitle
        }
      }
    }
`;

const FindInfoTemplate = (props) => {
  const { data, errors } = props;
  const post = data && data.post;
  const getPageUrl = getPageUrlOutOfSanityReference(post)

  try {
    return (
      <Layout>
        {errors && <SEO title="GraphQL Error" />}
        {post && (
          <SEO
            title={post.title || "Untitled"}
            description={toPlainText(post._rawExcerpt)}
            image={post.mainImage}
            canonical={getPageUrl}
          />
        )}

        {errors && (
          <Container>
            <GraphQLErrorList errors={errors} />
          </Container>
        )}

        {post && <FundInfo {...post} />}
      </Layout>
    );
  } catch (e) {
    console.log("FindInfoTemplate", e);
    return (null);
  }

};

export default FindInfoTemplate;
