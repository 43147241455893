import React from "react";
import * as styles from "./factsheetLink.module.css";

import { Grid, Button, Typography } from "@material-ui/core";

//<a className={styles.pinkCta} href={cta.link}>{cta.title}</a>

const FactsheetLink = ({ factsheetUrl, partner, newTab }) => {

  var ctaStyle = styles.pinkCta;
  var ctaStyleLeftStripe = styles.pinkCta;

  if ((typeof partner == "undefined") || (partner == false)) {
    ctaStyle = styles.pinkCta;
    ctaStyleLeftStripe = styles.leftStripe;
  }
  else {
    ctaStyle = styles.orangeCta;
    ctaStyleLeftStripe = styles.leftStripeOrange;
  }

  if (factsheetUrl && factsheetUrl !== '-') {

    return (<Grid
      container
      className={styles.ctaWidgetContainer}
      alignItems="center"
      justify="center"
    >
      <div className={ctaStyleLeftStripe}></div>
      <Grid
        item
        spacing={3}
        xs={12}
        sm={7}
        md={7}
        lg={8}
        xl={9}
        className={styles.leftSideTextContainer}
      >
        <p>
          <div class="makeStyles-margin-169">
            <div>
              <Typography variant="body1">
                Find out more
              </Typography>
              <Typography variant="body1">
                Download the factsheet for more details
              </Typography>
            </div>
          </div>
        </p>
      </Grid>

      <Grid
        item
        xs={12}
        sm={5}
        md={5}
        lg={4}
        xl={3}
        spacing={3}
        className={styles.anchorContainer}
      >
        <a href={factsheetUrl} target={newTab ? "_blank" : ''} className={`${styles.pinkCta} ${ctaStyle}`}>FACTSHEET</a>
      </Grid>
    </Grid>);
  } else {
    return (<></>);
  }
};

export default FactsheetLink;
