import React, { useState, useRef, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Button } from "@material-ui/core";
import StarIcon from "./icon/Star.svg";
import EmptyStarIcon from "./icon/empty-star.svg";
import GreenTick from "./icon/green-tick.svg";
import Rating from "@material-ui/lab/Rating";
import useStarIcons from "./starIcons"

const useStyles = makeStyles((theme) => ({
  reviewCard: {
    backgroundColor: "#FFFFFF",
    border: "1px solid #DBE3EF",
    boxShadow: "0px 2px 4px rgba(64, 64, 64, 0.1)",
    borderRadius: "8px",
    padding: "1em",
    // height: "100%",
    // position: "relative",
  },
  reviewCardSetHeight: {
    height: "290px",
    backgroundColor: "#FFFFFF",
    border: "1px solid #DBE3EF",
    boxShadow: "0px 2px 4px rgba(64, 64, 64, 0.1)",
    borderRadius: "8px",
    padding: "1em",
    // position: "relative",
  },
  reviewCardNonAdviser: {
    backgroundColor: "#FFFFFF",
    border: "1px solid #DBE3EF",
    boxShadow: "0px 2px 4px rgba(64, 64, 64, 0.1)",
    borderRadius: "8px",
    padding: "1em",
    // height: "100%",
    // position: "relative",
  },
  reviewCardSetHeightNonAdviser: {
    height: "220px",
    backgroundColor: "#FFFFFF",
    border: "1px solid #DBE3EF",
    boxShadow: "0px 2px 4px rgba(64, 64, 64, 0.1)",
    borderRadius: "8px",
    padding: "1em",
    // position: "relative",
  },
  reviewType: {
    color: "#50586C",
    fontSize: "16px",
    lineHeight: "24px",
  },
  reviewDateContainer: {
    paddingTop: ".5em",
    paddingBottom: ".5em",
    width: "50%",
    display: "inline-block",
  },
  dateLabel: {
    fontSize: "16px",
    lineHeight: "24px",
    color: "#BDBDBD",
  },
  dateContainer: {
    textAlign: "right",
  },
  titleLabel: {
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#262641",
    whiteSpace: "no-wrap",
    display: "-webkit-box",
    wordWrap: "break-word",
    overflow: "hidden",
    textOverflow: "ellipsis",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    width: "100%",
    marginBottom: "8px ",
  },
  titleLabelOpen: {
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#262641",
    // whiteSpace: 'no-wrap',
    // display: '-webkit-box',
    // wordWrap: 'break-word',
    // overflow: 'hidden',
    // minHeight: "48px",
    // textOverflow: 'ellipsis',
    // WebkitLineClamp: 2,
    // WebkitBoxOrient: 'vertical',
    width: "100%",
    marginBottom: "8px ",
  },
  reviewBody: {
    fontSize: "16px",
    lineHeight: "24px",
    color: "#2F2F4E",
    display: "-webkit-box",
    wordWrap: "break-word",
    overflow: "hidden",
    height: "72px",
    textOverflow: "ellipsis",
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    width: "100%",
  },
  reviewBodyOpen: {
    fontSize: "16px",
    lineHeight: "24px",
    color: "#2F2F4E",
    // whiteSpace: 'no-wrap',
    // paddingBottom: '2.3em',
    // display: '-webkit-box',
    // wordWrap: 'break-word',
    // overflow: 'hidden',
    // height: '72px',
    // textOverflow: 'ellipsis',
    // WebkitLineClamp: 3,
    // WebkitBoxOrient: 'vertical',
    width: "100%",
  },
  nameLabel: {
    fontSize: "16px",
    lineHeight: "24px",
    color: "#828282",
    paddingTop: ".5em",
    letterSpacing: "0.04em",
    textTransform: "uppercase",
    fontWeight: "600",
  },
  nameContainer: {
    paddingTop: ".4em",
  },
  reviewHeading: {
    color: "#2F2F4E",
    fontSize: "36px",
    lineHeight: "47px",
    fontWeight: "600",
    paddingBottom: "1em",
    paddingTop: "1em",
    [theme.breakpoints.down("sm")]: {
      paddingTop: 0,
    },
  },
  verifiedLabel: {
    color: "#009089",
    fontSize: "12px",
    lineHeight: "18px",
    fontWeight: "500",
    display: "inline-block",
    verticalAlign: "middle",
    paddingLeft: "1em",
  },
  verifiedImage: {
    display: "inline-block",
    verticalAlign: "middle",
  },
  fullWidth: {
    width: "100%",
  },
  halfWidth: {
    width: "50%",
    display: "inline-block",
  },
  readButton: {
    cursor: "pointer",
    color: "#0070EA",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    letterSpacing: "0px",
  },
}));

export default function DisplayFundReviewsCard(props) {
  const { review, dictionary, flushState, isForAdivser } = props;
  const classes = useStyles();
  const starIcons = useStarIcons();
  const [open, setOpen] = useState(false);
  const [height, setHeight] = useState(0);
  const [scrollHeight, setScrollHeight] = useState(0);

  //console.log("DisplayFundReviewsCard",props)

  const ref = useRef(null);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };
  useEffect(() => {
    setHeight(ref.current.clientHeight);
    setScrollHeight(ref.current.scrollHeight);
  });

  useEffect(() => {
    setOpen(false);
  }, [flushState]);
  return (
    <Grid item xs={12} md={isForAdivser ? 4 : 6}>
      <Grid
        container
        className={
          isForAdivser
            ? open
              ? classes.reviewCard
              : classes.reviewCardSetHeight
            : open
              ? classes.reviewCardNonAdviser
              : classes.reviewCardSetHeightNonAdviser
        }
      >
        <Grid className={classes.fullWidth}>
          <Grid
            item
            xs={review.IsVerified ? 6 : 12}
            className={
              review.IsVerified ? classes.halfWidth : classes.fullWidth
            }
          >
            {dictionary !== undefined ? (
              <Typography variant="body2" className={classes.reviewType}>
                {review.IsClient
                  ? dictionary.nodes.find(
                    (x) => x.key === "adviser_page_reviews_client_review"
                  ).value
                  : dictionary.nodes.find(
                    (x) => x.key === "adviser_page_reviews_reader_feedback"
                  ).value}
              </Typography>
            ) : (
              ""
            )}
          </Grid>
          {review.IsVerified && (
            <div
              item
              xs={6}
              alignItems="flex-end"
              className={`${classes.dateContainer} ${classes.halfWidth}`}
            >
              <img src={GreenTick} className={classes.verifiedImage} />
              <Typography variant="body2" className={classes.verifiedLabel}>
                Verified
              </Typography>
            </div>
          )}
          <div
            item
            xs={6}
            alignItems="flex-start"
            className={classes.reviewDateContainer}
          >
            <Rating
              readOnly
              name="Rating"
              precision={0.5}
              value={review.StarRating/2}
              icon={
                <img
                  src={StarIcon}
                  alt="StarIcon"
                  className={starIcons.starImage}
                />
              }
              emptyIcon={
                <img
                  src={EmptyStarIcon}
                  alt="EmptyStarIcon"
                  className={starIcons.starImage}
                />
              }
            />
          </div>
          <div
            item
            xs={6}
            alignItems="flex-end"
            className={`${classes.dateContainer} ${classes.reviewDateContainer}`}
          >
            <Typography variant="body2" className={classes.dateLabel}>
              {review.Date}
            </Typography>
          </div>
          <Grid item xs={12}>
            <Typography
              variant="body2"
              className={open ? classes.titleLabelOpen : classes.titleLabel}
            >
              {review.ReviewTitle}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body2"
              className={open ? classes.reviewBodyOpen : classes.reviewBody}
              ref={ref}
            >
              {review.Review}
            </Typography>
          </Grid>
          {scrollHeight > height ? (
            <a onClick={handleClick}>
              <Typography variant="button" className={`${classes.readButton}`}>
                {open ? "Read Less >" : "Read More >"}
              </Typography>
            </a>
          ) : open ? (
            <a onClick={handleClick}>
              <Typography variant="button" className={`${classes.readButton}`}>
                Read Less >
              </Typography>
            </a>
          ) : (
            <Grid className={classes.fallback} />
          )}
        </Grid>
        <Grid container className={classes.nameContainer} alignItems="flex-end">
          <Typography variant="body2" className={classes.nameLabel}>
            {review.ReaderFirstName}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
