import * as styles from "./blog-post.module.css";
import ContainerForPages from "./containerForPages";
import Container from "./container";
import PortableText from "./portableText";
import React, { useEffect, useState } from "react";
import { buildImageObj, publishDate } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import SideBar from "./sideBar";
import AnchorMenu from "./sidebarComponents/anchorMenu";
import FooterDisclaimer from "./footer-disclaimer";

import {
  AppBar,
  Grid,
  Typography,
  Toolbar,
  ListItem,
  List,
  IconButton,
  Hidden,
  Drawer,
  CssBaseline,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "gatsby";
import { MainImage } from "gatsby-plugin-image";
import CtaWidget from "./ctaWidget/ctaWidget";
import WidgetComponent from "./sidebarComponents/widgetComponent";
import DisplayFundReviewsWidget from "./displayFundReviewsWidget"

import FundByID from "./funds/fundByID"
import FundListCardForFundInfo from "./funds/fundListCardForFundInfo";
import FactsheetLink from "./funds/fundComponents/factsheetLink";

const useStyles = makeStyles((theme) => ({
  mainImage: {
    borderRadius: "12px",
  },
  linkTextNotActive: {
    color: "#50586C",
    fontSize: "14px",
    fontWeight: "500"
  },
  linkTextActive: {
    color: "#262641",
    fontSize: "14px",
    fontWeight: "600",
  },
  authorMargin: {
    marginTop: "31px",
  },
  dateMargin: {
    color: "#4A4A68",
    paddingBottom: '15px'
  },
  grey: {
    // backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1000px' height='1000px'%3E%3Cdefs%3E%3ClinearGradient id='g1'%3E%3Cstop stop-color='%239796F0'/%3E%3Cstop offset='1' stop-color='%23FBC7D4'/%3E%3C/linearGradient%3E%3C/defs%3E%3Ccircle cx='500' cy='500' r='400' style='fill-opacity: .1;' fill='url(%23g1)'/%3E%3C/svg%3E")`,
    // backgroundRepeat: "repeat-y",
    // backgroundSize: "1000px 1000px",
    // backgroundPosition: "top center",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      backgroundSize: "contain",

    },
  },
  modalBackground: {
    // backdropFilter: "blur(100px)",
    maxWidth: "100%",
    paddingTop: "14px",
    paddingBottom: "60px",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  seventyFive: {
    width: "75%",
    "@media (max-width:600px)": {
      width: "100%",
    },
  },
  twentyFive: {
    width: "25%",
    justifyContent: "flex-end",
    "@media (max-width:600px)": {
      width: "100%",
      justifyContent: "flex-start",
    },
  },
  marginFromTitle: {
    marginTop: "32px",
    marginBottom: "0px",
  },
  dot: {
    color: "#B2C2C3",
    marginLeft: "7px",
    marginRight: "7px",
  },
  sidebar: {
    width: "300px",
    [theme.breakpoints.down("sm")]: {
      width: '100%',
      paddingBottom: "20px",
    },
  },
  content: {
    width: "calc(100% - 370px)",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      paddingBottom: "20px",
    },
  },
  adContent: {
    width: "100%",
    textAlign: "center",

    [theme.breakpoints.down("sm")]: {
      display: 'none'
    },
  },
  sponsored: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '600',
    letterSpacing: '0.04em',
    textTransform: 'uppercase',
    color: '#50586C',
    paddingTop: '1em',
    paddingBottom: '1em'
  },
  logoCell: {
    position: 'relative'
  },
  logoCellMobile: {
    textAlign: 'right'
  },
  articleImage: {
    borderRadius: '8px',
    maxHeight: '102px',
    maxWidth: '102px',
    width: '102px',
    height: '102px',
    objectFit: 'cover',
    display: 'block',
    float: 'right'
  },
  articleImageMobile: {
    borderRadius: '8px',
    maxHeight: '120px'
  },
  logoContainer: {
    borderRadius: '8px',
    minHeight: '150px',
    padding: '24px',
    backgroundColor: '#3C465F'
  },
  fundProvider: {
    fontSize: '36px',
    lineHeight: '47px',
    fontWeight: '600',
    color: '#FFFFFF'
  },
  fundProviderMobile: {
    fontWeight: '600',
    color: '#FFFFFF'
  },
  fundTitle: {
    fontSize: '20px',
    lineHeight: '28px',
    fontWeight: '600',
    color: '#FFFFFF'
  },
  fundTitleMobile: {
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: '400',
    color: '#FFFFFF'
  },
  titleContainer: {
    position: 'relative'
  },
  titleWrapper: {
  },
  breadcrumb: {
    paddingBottom: '24px',
  },
  mobileLogoContainer: {
    backgroundColor: '#3C465F',
    width: '100%',
    marginTop: '39px',
    paddingTop: '4em',
    paddingBottom: '4em'
  },
  bcSpan: {
    color: "#50586C",
    fontSize: "14px",
  fontWeight: "500",
  },
  disclaimerFlexContainer:{
    display: "flex",
    marginLeft: "auto",
    marginRight: "auto",
  }
}));


function FundInfo(props) {
  const {
    title,
    fundProvider,
    dashboardId,
    _rawBody,
    sponsoredFund,
    subtitle,
    slug,
    _rawSponsoredAbout,
    widgets,
    mainImage,
    fundCompany
  } = props;

  const [funds, setFunds] = useState([]);
  const [factsheetUrl, setFactsheetUrl] = useState('');

  const apiURL = process.env.GATSBY_FUNDSAPI + dashboardId;

  useEffect(() => {
    (async () => {
      await getFundInfo();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      //console.log('funds: ', funds);

      if (funds && funds.length > 0) {
        if (funds[0].URL) {
          //console.log('factsheet url: ', funds[0].URL);
          setFactsheetUrl(funds[0].URL);
        }
      }
    })();
  }, [funds]);

  var getFundInfo = async function () {
    var funds = await getFunds();

    if (funds.length > 0) {
      setFunds(funds);
    }
  }

  var getFunds = async function (model) {
    return fetch(apiURL, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(model)
    })
      .then((response) => response.json())
      .then((responseJson) => { return responseJson });
  }

  const classes = useStyles();

  return (
    <article className={classes.grey}>

      <ContainerForPages>
        <Hidden mdUp implementation="js">
          <div className={`${classes.mobileLogoContainer} ${styles.fundHeaderBackgroundMobile}`}>
            <Container>
              <Grid container className={classes.mobileLogoWrapper} alignItems="center">

                <Grid item xs={mainImage === null ? 12 : 8} className={classes.titleContainer}>
                  <Grid container className={classes.titleWrapper}>
                    <Grid item xs={12}>
                      <Typography variant="h1" className={classes.fundProviderMobile}>
                        {title}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h2" className={classes.fundTitleMobile}>
                        {fundProvider}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                {mainImage && mainImage.asset &&
                  <Grid item xs={4} className={classes.logoCellMobile}>
                    <img
                      src={imageUrlFor(buildImageObj(mainImage))
                        .width(220)
                        .height(220)
                        .fit("crop")
                        .url()}
                      className={classes.articleImageMobile}
                    />
                  </Grid>
                }
              </Grid>
            </Container>
          </div>
        </Hidden>
        <Container>
          <Grid
            container
            className={classes.modalBackground}

          >
            <Grid item className={classes.content}>
              <Hidden mdDown implementation="js">
                <Typography variant="subtitle2" className={classes.breadcrumb}>
                  <Link
                    to="/"
                    style={{ textDecoration: "none" }}
                    className={classes.linkTextNotActive}
                  >
                    Home
                </Link>

                      <span className={classes.dot}>{" • "}</span>
                 <Link
                        to="/funds/"
                        style={{ textDecoration: "none" }}
                        className={classes.linkTextNotActive}
                 >
                        Funds
                </Link>
                      <span className={classes.dot}>{" • "}</span>

                  <span className={classes.bcSpan}>{title}</span>


                </Typography>
              </Hidden>

              <Hidden smDown implementation="js">
                <Grid container className={`${classes.logoContainer} ${styles.fundHeaderBackground}`} alignItems="center">
                  <Grid item xs={mainImage === null ? 12 : 9} className={classes.titleContainer}>
                    <Grid container className={classes.titleWrapper}>
                      <Grid item xs={12}>
                        <Typography variant="h1" className={classes.fundProvider}>
                          {title}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="h2" className={classes.fundTitle}>
                          {fundProvider}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  {mainImage && mainImage.asset &&
                    <Grid item xs={3} className={classes.logoCell}>
                      <img
                        src={imageUrlFor(buildImageObj(mainImage))
                          .width(102)
                          .height(102)
                          .fit("crop")
                          .url()}
                        className={classes.articleImage}
                      />
                    </Grid>
                  }
                </Grid>
              </Hidden>

              <Grid
                container
                item
                alignItems="stretch"
                justify="space-between"
                className={classes.marginFromTitle}
              >

                <Grid
                  container
                  item
                  className={classes.twentyFive}
                  alignItems="flex-start"
                >

                </Grid>
              </Grid>

              {dashboardId && <div>
                {funds && funds.map((f) => {
                  return <FundListCardForFundInfo fund={f} from="ID" />
                })}
              </div>}


              <Typography variant="h3" className={styles.bigtitle}>
                Fund summary
              </Typography>

              {_rawBody && <PortableText blocks={_rawBody} />}

              <FactsheetLink factsheetUrl={factsheetUrl} newTab={true} />

              {sponsoredFund === true &&
                <>
                  <Typography variant="h3" className={styles.bigtitle}>
                    About the fund manager
                </Typography>
                  <Typography variant="h4" className={classes.sponsored}>
                    sponsored
                </Typography>

                  {_rawSponsoredAbout && <PortableText blocks={_rawSponsoredAbout} />}
                </>
              }

              {dashboardId && <DisplayFundReviewsWidget companyID={dashboardId} fundPageHeaderStyling={true} />}

            </Grid>
            <Grid item sm={0} className={classes.sidebar}>



              {widgets && <WidgetComponent widgets={widgets} />}

            </Grid>
          </Grid>
          <Grid item className={classes.adContent}>
            {widgets && <WidgetComponent widgets={widgets} target="bottom" />}

          </Grid>

        </Container>
        <Grid className={classes.disclaimerFlexContainer} md={10} xs={11} item justify="center">
          <FooterDisclaimer />
        </Grid>
      </ContainerForPages>
    </article>
  );
}

export default FundInfo;
