import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Portal,
  Grid,
  ClickAwayListener,
  Button,
  Typography,
  Hidden,
} from "@material-ui/core";
import * as styles from "../landingPageComponents/adviserList.module.css";
import RateFundForm from "../popups/rateFundForm";
import Risk from "./fundComponents/risk";
import RatingCustom from "./fundComponents/rating";
import Info from "./fundComponents/info";
import ReaderRating from "./fundComponents/readerRating";
import RightIcon from "../icon/Right-Icon.svg";
import ContainerForSVG from "../containerForSVG";

import FundTypeFundInfoVariant from "./fundComponents/fundTypeFundInfoVariant";
import FundTypeFundListVariant from "./fundComponents/FundTypeFundListVariant";

import { toggleBlur, toggleOff } from "../../redux/reducers/blur";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .Mui-selected": {
      background:
        "#3C465F;",
      color: "white",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "14px",
      lineHeight: "20px",
      height: "3.5em",
      width: "3.5em",
      borderRadius: "1.75em",
      [theme.breakpoints.down("sm")]: {
        height: "3em",
        width: "3em",
        borderRadius: "1.5em",
        fontSize: "9px",
      },
    },
    "& .MuiPaginationItem-ellipsis": {
      paddingTop: ".8em",
    },
    "& .MuiPaginationItem-icon": {
      fontSize: "1.5rem",
    },
    "& .MuiPaginationItem-root": {
      [theme.breakpoints.down("sm")]: {
        minWidth: "initial !important",
      },
    },
    "& ul > li > button:not(.Mui-selected),.MuiPaginationItem-ellipsis": {
      backgroundColor: "white",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "14px",
      lineHeight: "20px",
      color: "#2F2F4E",
      height: "3.5em",
      width: "3.5em",
      borderRadius: "1.75em",
      [theme.breakpoints.down("sm")]: {
        height: "3em",
        width: "3em",
        borderRadius: "1.5em",
        fontSize: "9px",
      },
    },
  },
  dropdown: {
    position: "fixed",
    width: 400,
    height: 630,
    maxWidth: '100%',
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#fff",
    zIndex: 999,
    borderRadius: "8px",
    padding: "1.5em",
    border: "1px solid #DBE3EF",
    boxShadow: "0px 2px 4px rgba(64, 64, 64, 0.1)",
    [theme.breakpoints.down("xs")]: {
      width: 335,
      height: "auto",
      padding: "16px",
      overflowY: 'scroll'
    },
    [theme.breakpoints.down(345)]: {
      width: 300,
      padding: "16px",
    },
  },
  backdrop: {
    position: "fixed",
    width: "100%",
    height: "100%",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 999,
  },
  contactMeButton: {
    borderRadius: "8px",
    padding: "16px 24px",
    /* Drop shadow Mas */
    height: "56px",
    backgroundColor: "#DBE3EF",
    width: "194px",
    /* Drop shadow Mas */
    filter: "drop-shadow(0px 2px 4px rgba(64, 64, 64, 0.1))",
    [theme.breakpoints.down("sm")]: {
      // maxWidth: "150px",
      // maxHeight: "150px",
      marginLeft: "0",
    },
    [theme.breakpoints.down("1280")]: {
      // maxWidth: "150px",
      // maxHeight: "150px",
      width: "100%",
    },
    "&:hover": {
      backgroundColor: "#3C465F",
      color: "#FFFFFF",
      boxShadow: "0px 2px 4px rgba(64, 64, 64, 0.1)",
      "& span": {
        color: "#FFFFFF",
      },
    },
  },
  contactMeButtonText: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "600",
    color: "#3C455C",
    width: "100%",
    letterSpacing: "0.04em",
    textTransform: "uppercase",
    textAlign: "center",
    "&:hover": {
      color: "#FFFFFF",
    },
  },
  contactMeButtonContainer: {
    [theme.breakpoints.down("1280")]: {
      width: "100%",
    },
  },
  rateButtonContainer: {
    paddingTop: "14px",
    [theme.breakpoints.up("lg")]: {
      paddingTop: "21px",
    },
  },
  rateButtonLabel: {
    cursor: "pointer",
    padding: "1em 2em 1em 2em",
  },
  filtersContainer: {
    width: "100%",
    backgroundColor: "white",
    borderRadius: "8px",
    padding: "1.6em",
    background: "#fff",
    border: "1px solid #DBE3EF",
    marginBottom: "24px",
    boxShadow: "0px 2px 4px 0px #4040401A",

    [theme.breakpoints.down("959")]: {
      marginBottom: "16px",
    },
  },
  rightIcon: {
    paddingLeft: "8px",
  },
  ratingsContainerMobile: {
    padding: "16px",
    backgroundColor: "#FAFCFE",
    borderRadius: "8px",
    border: "1px solid #DBE3EF",
    marginTop: "16px",
  },
  ratingsContainerMobileV2: {
    padding: "16px",
    backgroundColor: "#FAFCFE",
    borderRadius: "8px",
    border: "1px solid #DBE3EF",
    marginTop: "8px",
  },
  ratingsContainerMobileSplitter: {
    paddingBottom: "22px",
  },
  //the below ones are a workaround for MUI Hidden not accepting custom values
  customHidden: {
    position: "relative",
    // [theme.breakpoints.down('1280')]: {
    //   display: "none"
    // },
  },
  customHiddenv2: {
    [theme.breakpoints.up("1280")]: {
      display: "none",
    },
  },
  contactButtonDesktop: {
    position: "absolute",
    right: "0",
    bottom: "0",
  },
}));

function FundsListCard(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const {
    ActivePassive,
    Chilli,
    CompanyName,
    Cost,
    Crown,
    Equity,
    Fixed_Interest,
    Fund_name,
    Multi_Asset,
    Reader_Rating,
    Return_1_yr,
    Return_5_yr,
    Sustainable_ESG,
    URL,
    dashboardId,
    BM_Provider_ID,
    BM_Fund_ID
  } = props.fund;

  const { isBlur, dispatch } = props;

  const handleClick = () => {
    setOpen((prev) => !prev);
    dispatch(toggleBlur());
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  useEffect(() => {

    if(!open){
      dispatch(toggleOff())
    }
    (async () => {
      if (open) {
        if (window.dataLayer) {
          var model = {
            Location: window.location.href,
            FundId: BM_Fund_ID
          };

          window.dataLayer.push({ 'fund_review_popup_open_value': JSON.stringify(model) });

          window.dataLayer.push({ event: 'fund_review_popup_open' });
        }
      }
    })();
  }, [open]);

  var moreInfoLink = "/funds/" + Fund_name.replace(/\s/g, "-").toLowerCase();

  return (
    <div className={classes.filtersContainer}>
      <Hidden mdDown implementation="js">
        <Grid
          container
          justify="space-between"
          className={classes.customHidden}
        >
          <Grid>
            <FundTypeFundInfoVariant
              Equity={Equity}
              ActivePassive={ActivePassive}
              Fixed_Interest={Fixed_Interest}
              Multi_Asset={Multi_Asset}
              Sustainable_ESG={Sustainable_ESG}
              title="Fund type"
            />
          </Grid>
          <Grid>
            <Risk rating={Chilli} />
            <RatingCustom rating={Crown} />
          </Grid>
          <Grid>
            <Grid container>
              <Info
                title="Return 1yr"
                amount={Return_1_yr}
                disclaimerLocation="fund_list_disclaimer_text_return1yr"
              />
              <Info
                title="Return 5yr"
                amount={Return_5_yr}
                disclaimerLocation="fund_list_disclaimer_text_return5yr"
              />
              <Info
                title="Cost"
                amount={Cost}
                disclaimerLocation="fund_list_disclaimer_text_cost"
              />
              <ReaderRating
                title="Value Rating"
                amount={Reader_Rating}
                disclaimerLocation="fund_list_disclaimer_text_reader_rating"
              />
            </Grid>
            <Grid container justify="flex-end"></Grid>
          </Grid>
          <div
            className={`${classes.contactMeButtonContainer} ${classes.contactButtonDesktop}`}
          >
            {open && <div className={classes.backdrop} />}
            <ClickAwayListener onClickAway={handleClickAway}>
              <div className={classes.rateButtonContainer}>
                <Button
                  className={`${classes.contactMeButton}`}
                  onClick={handleClick}
                >
                  <Typography
                    variant="button"
                    className={`${classes.contactMeButtonText}`}
                  >
                    <ContainerForSVG>
                      REVIEW FUND
                      <img
                        src={RightIcon}
                        alt="RightIcon"
                        className={classes.rightIcon}
                      />
                    </ContainerForSVG>
                  </Typography>
                </Button>
                {open ? (
                  <Portal>
                    <Grid className={classes.dropdown}>
                      <RateFundForm
                        onClickingAwayHandler={handleClickAway}
                        fundId={BM_Fund_ID}
                        fundName={Fund_name}
                      />
                    </Grid>
                  </Portal>
                ) : null}
              </div>
            </ClickAwayListener>
          </div>
        </Grid>
      </Hidden>
      <Hidden lgUp implementation="js">
        <Grid>
          <Grid>
            <FundTypeFundListVariant
              Equity={Equity}
              ActivePassive={ActivePassive}
              Fixed_Interest={Fixed_Interest}
              Multi_Asset={Multi_Asset}
              Sustainable_ESG={Sustainable_ESG}
              title="Fund type"
            />
          </Grid>
          <Grid
            container
            justify="space-between"
            className={classes.ratingsContainerMobileV2}
          >
            <RatingCustom rating={Crown} isLargeVariant="true" />
            <Risk rating={Chilli} isLargeVariant="true" />
          </Grid>
          <Grid>
            <Grid
              container
              justify="space-between"
              direction="row"
              className={classes.ratingsContainerMobile}
            >
              <Grid>
                <Grid className={classes.ratingsContainerMobileSplitter}>
                  <Info
                    title="Return 1yr"
                    amount={Return_1_yr}
                    disclaimerLocation="fund_list_disclaimer_text_return1yr"
                    isLargeVariant="true"
                  />
                </Grid>
                <Info
                  title="Cost"
                  amount={Cost}
                  disclaimerLocation="fund_list_disclaimer_text_cost"
                  isLargeVariant="true"
                />
              </Grid>
              <Grid>
                <Grid className={classes.ratingsContainerMobileSplitter}>
                  <Info
                    title="Return 5yr"
                    amount={Return_5_yr}
                    disclaimerLocation="fund_list_disclaimer_text_return5yr"
                    isLargeVariant="true"
                  />
                </Grid>
                <ReaderRating
                  title="Reader Rating"
                  amount={Reader_Rating}
                  disclaimerLocation="fund_list_disclaimer_text_reader_rating"
                  isLargeVariant="true"
                />
              </Grid>
            </Grid>
            <Grid container justify="flex-end">
              <div className={classes.contactMeButtonContainer}>
                {open && <div className={classes.backdrop} />}
                <ClickAwayListener onClickAway={handleClickAway}>
                  <div className={classes.rateButtonContainer}>
                    <Button
                      className={`${classes.contactMeButton}`}
                      onClick={handleClick}
                    >
                      <Typography
                        variant="button"
                        className={`${classes.contactMeButtonText}`}
                      >
                        REVIEW FUND
                      </Typography>
                    </Button>
                    {open ? (
                      <Portal>
                        <Grid className={classes.dropdown}>
                          <RateFundForm
                            onClickingAwayHandler={handleClickAway}
                            fundId={BM_Fund_ID}
                            fundName={Fund_name}
                          />
                        </Grid>
                      </Portal>
                    ) : null}
                  </div>
                </ClickAwayListener>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Hidden>
    </div>
  );
}

export default connect((state) => ({
  isBlur: state.blurReducer.isBlur
}), null)(FundsListCard);
